import React, { useEffect, useRef } from 'react';
import Layout from './layout';
import './css/home.css';

const Home = () => {
    const h2Ref = useRef(null);
    const formContainerRef = useRef(null);

    useEffect(() => {
        const checkAndChangeTextColor = () => {
            const h2Element = h2Ref.current;
            const formContainerElement = formContainerRef.current;
            if (!h2Element || !formContainerElement) return;

            const h2Rect = h2Element.getBoundingClientRect();
            const formRect = formContainerElement.getBoundingClientRect();

            // Check if h2 element overlaps with the form container
            const isOverlapping = !(h2Rect.bottom < formRect.top || h2Rect.top > formRect.bottom);

            if (isOverlapping) {
                h2Element.style.color = 'black';
                // Remove text shadow when not overlapping
                h2Element.style.textShadow = 'none';
            } else {
                h2Element.style.color = 'white';
                // Apply a tiny black outline using text shadow for better visibility
                h2Element.style.textShadow = '0 0 3px black, 0 0 5px black';
            }
        };

        window.addEventListener('scroll', checkAndChangeTextColor);
        checkAndChangeTextColor(); // Initial check

        return () => window.removeEventListener('scroll', checkAndChangeTextColor);
    }, []);

    return (
        <Layout>
            <div className="container home-page-container">
                <div ref={formContainerRef} className="form-container-home"> {/* Attach the ref */}
                    <div id="panelContainer" className="threeTwoDown">
                        <div className="col-sm-12">
                        <h2 ref={h2Ref} style={{ color: 'black' }}>Welcome to Riverwalk Master Association!</h2> {/* Initial color set to black */}
                            <p><br/></p>
                            <p>If you do not have an online account, please select Register at the top right. If you already have an account, please Sign in.</p>
                            <p>If you need any assistance, please reach out to us by going to "Contact Us" at the top right.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Home;